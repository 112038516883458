/*
 * @Descripttion: 
 * @Author: Fanghong
 * @Date: 2023-02-09 14:55:40
 * @LastEditors: Shengping
 */

/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: 'homePage',
    component: () => import('@/views/HomePage/HomePage.vue'),
  },
  {
    path: "/videoPage",
    name: 'videoPage',
    component: () => import('@/views/VideoPage/VideoPage.vue'),
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('@/views/AppPage/AppPage.vue')
  },
  {
    path: '/love',
    name: 'love',
    component: () => import('@/views/BCouples/BCouples.vue')
  },
  {
    path: "/videoBox",
    name: 'videoBox',
    component: () => import('@/views/VideoBox/VideoBox.vue'),
  },
  {
    path: "/super",
    name: 'super',
    component: () => import('@/views/SuperControl/SuperControl.vue'),
  },
  {
    path: '/d',
    name: 'd',
    component: () => import('@/views/ChildDownload/ChildDownload.vue')
  },
  {
    path: '/p',
    name: 'p',
    component: () => import('@/views/ParentDownload/ParentDownload.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutPage/AboutPage.vue')
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: () => import('@/views/FaqUs/FaqUs.vue')
  }, {
    path: '/FaqItem',
    name: 'FaqItem',
    component: () => import('@/views/FaqUs/FAQItem.vue')
  } ,
  {
    path: '*',  // 匹配所有未定义的路由跳转首页
    redirect: '/'
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
});
//下周测试
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth); // 假设路由配置中可以通过meta字段设置是否需要登录验证
  if (requiresAuth &&!store.state.isLoggedIn) { // 如果需要登录且未登录
    next('/'); // 重定向到登录页
  } else {
    next();
  }
});

export default router;
